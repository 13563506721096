import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Flex, Image, Link, Text, Heading, Box, chakra } from "@chakra-ui/react"
import Tags from "../tags"

const tags = {
  root: { mt: "24px" },
  tag: { fontWeight: "bold" },
}

const styles = {
  root: {
    mt: "44px",
    pt: "44px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderTop: "1px solid #E0E0E0",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "48px" },
    fontWeight: "bold",
    lineHeight: { base: "28px", md: "28px", lg: "56px" },
    color: "#1B263F",
    mt: "24px",
  },
  date: {
    mt: "24px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "266%",
    color: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#1B263F",
  },
  tags,
  link: {
    mt: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#3399FF",
    width: "100%",
  },
}

const DefaultImage = props => (
  <Box sx={props.sx}>
    <StaticImage
      src="../../images/blog/blog-placeholder.png"
      alt={props.alt}
      placeholder="blurred"
    />
  </Box>
)

const LinkWrapper = ({ slug, type, link, children }) =>
  !link ? (
    <Link as={GatsbyLink} to={`/${type}/${slug}/`} sx={styles.link}>
      {children}
    </Link>
  ) : (
    <chakra.a
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      sx={styles.link}
    >
      {children}
    </chakra.a>
  )

const Article = ({
  title,
  image,
  slug,
  description,
  tags,
  date,
  type,
  link,
}) => (
  <Flex sx={styles.root}>
    <LinkWrapper slug={slug} type={type} link={link}>
      {image ? (
        <Image
          as={GatsbyImage}
          alt={title}
          image={image}
          placeholder="blurred"
          sx={styles.image}
        />
      ) : (
        !link && <DefaultImage alt={title} sx={styles.image} />
      )}
      <Heading sx={styles.title}>{title}</Heading>
    </LinkWrapper>
    <Tags tags={tags} styles={styles.tags} />
    <Text sx={styles.date}>{date}</Text>
    <Box sx={styles.text} dangerouslySetInnerHTML={{ __html: description }} />
    {!link && (
      <Link as={GatsbyLink} to={`/${type}/${slug}/`} sx={styles.link}>
        read more...
      </Link>
    )}
  </Flex>
)

Article.propTypes = {
  type: PropTypes.string,
}

Article.defaultProps = {
  type: "blog",
}

export default Article
